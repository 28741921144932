<template>
  <div class="video-cover" @click="goFullPlay(item)">
    <div class="top">
      <ImgDecypt class="cover-img" :src="item.videoCover" />
      <div class="video-info flex-between">
        <div class="left">
          <span class="left-play"></span>{{ item.watchTimes | watchCount }}
        </div>
        <div class="right">{{ item.playTime | videotime }}</div>
      </div>
      <div class="vip" v-if="item.isVip">
        <span>VIP</span>
      </div>
      <div class="vip" v-else-if="item.price">
        <span class="money"></span>
      </div>
      <div v-else></div>
    </div>
    <div class="title ellipsis">
      {{ item.content }}
    </div>
  </div>
</template>
<script>
import { jumpVideo } from "@/utils/index.js";
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    goFullPlay(item) {
      //点击视频列表去全屏播放页面
      jumpVideo(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.video-cover {
  width: 169px;
  margin-top: 13px;
}
.top {
  font-size: 0;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}
.cover-img {
  width: 100%;
  height: 96px;
}
.video-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20px;
  align-items: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
  padding: 0 6.3px;
  box-sizing: border-box;
  font-size: 8px;
  color: #ffffff;
}
.left-play {
  display: inline-block;
  width: 8.9px;
  height: 9.4px;
  background: url("../../assets/png/play_icon.png") center center no-repeat;
  background-size: 100%;
}
.vip {
  position: absolute;
  top: 0;
  right: 0;
  width: 33px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  color: #ffffff;
  border-bottom-left-radius: 6px;
  font-size: 11px;
  font-weight: bold;
  background: $vermillion;
}
.money {
  display: inline-block;
  width: 11.2px;
  height: 11.2px;
  background: url("../../assets/png/money_icon.png") center center no-repeat;
  background-size: 100%;
  vertical-align: middle;
}
.title {
  margin-top: 8px;
  font-size: 13px;
}
</style>
