<template>
  <div class="tag">
    <!-- <AdvSwiper class="adv" :advList="advList" /> -->
    <div class="advBox">
      <div
        class="advItem"
        v-for="(item, index) in gridAdvList"
        :key="index"
        @click="jumpAdd(item)"
      >
        <div class="advImgBox">
          <ImgDecypt :src="item.pictureUrl" :key="item.pictureUrl" />
        </div>
        <div class="advTitle">{{ item.name }}</div>
      </div>
    </div>
    <v-touch
      @swipeleft="swipeLeft"
      @swiperight="swipeRight"
      :swipe-options="{ direction: 'horizontal' }"
      v-if="!isLoading"
    >
      <div class="tag-tag">
        <!-- <div v-if="getterAdsGood.length>0">
          <ImgDecypt class="tag-img" :src="item.background" v-for="item in getterAdsGood" :key="item.id" @click.native="toGoodVideo(item.id)" />
        </div>
        <div class="sequ" v-if="adsSequ.length>0">
          <div><span class="red-heard"></span>色区</div>
          <ImgDecypt class="sequ-img" :src="item.pictureUrl" v-for="item in adsSequ[0].items" :key="item.id" @click.native="toSeArea" />
        </div> -->
        <div
          class="guochan sequ"
          v-for="(item, index) in dataList"
          :key="item.id"
        >
          <div class="tag-title">
            <span>{{ item.name }}</span>
            <div
              tag="div"
              @click="toMoreVideo(item.id, item.name)"
              class="more"
            >
              查看更多<van-icon name="arrow" />
            </div>
            <!-- <router-link tag="div" :to="{path:'/moreVideo',query:{id:item.id,name:item.name}}" class="more">
              查看更多<span class="go-more"></span>
            </router-link> -->
          </div>
          <!-- 小横屏 -->
          <div class="smallHorizontal" v-if="item.showConf == 1">
            <videocover
              class="smallHorizontalItem"
              :item="items"
              v-for="items in item.medias"
              :key="items.id"
            />
          </div>
          <div class="bigHorizontal" v-else-if="item.showConf == 2">
            <videocover class="bigHorizontalItem" :item="item.medias[0]" />
          </div>
          <div class="video-list flex-between" v-else>
            <videocover
              :item="items"
              v-for="items in item.medias"
              :key="items.id"
            />
          </div>
          <div
            @click="jumpAdd(tagAd[index % tagAd.length])"
            v-if="tagAd.length > 0"
          >
            <ImgDecypt
              class="l_adv"
              :src="tagAd[index % tagAd.length].pictureUrl"
            />
            <!-- <ImgDecypt class="l_adv" :src="item.adv&&item.adv.pictureUrl" /> -->
          </div>
          <!-- <AdvSwiper
            :advList="tagAd"
            v-if="(index + 1) % 3 == 0 && tagAd.length > 0"
            class="l_adv"
          /> -->
        </div>
      </div>
    </v-touch>
    <div class="loading_box" v-else>
      <img class="loading" src="@/assets/gif/loading.gif" />
    </div>
  </div>
</template>
<script>
import videocover from "../widget/videoList.vue";
import "swiper/dist/css/swiper.css";
import { newTagList } from "@/api/app.js";
import { buried } from "@/api/index.js";
// import AdvSwiper from "@/components/AdvSwiper/index.vue";
import { getAdItem, AdType } from "@/utils/getAdv.js";
import { jumpAdv } from "@/utils/getAdv.js";
export default {
  components: {
    videocover,
    // AdvSwiper,
  },
  data() {
    return {
      isLoading: true,
      tagList: JSON.parse(sessionStorage.getItem("appConfig"))?.pageCfg,
      adsSequ: JSON.parse(sessionStorage.getItem("appConfig")).ads?.filter(
        (item) => {
          return item.posType == 10;
        }
      ), //专区色区
      adsGood: JSON.parse(sessionStorage.getItem("appConfig")).pageCfg?.filter(
        (item) => {
          return item.id == "spec_coll";
        }
      ), //好片大赏
      getterAdsGood: [],
      dataList: [], //视频列表数据
      tagAd: [],
      advList: [],
      gridAdvList: [],
    };
  },
  created() {
    this.tagAd = getAdItem(AdType.columnAd);
    this.advList = getAdItem(AdType.homeArea);
    this.gridAdvList = getAdItem(AdType.homeAdv).slice(0, 10);
    // console.log(this.gridAdvList);
    this.getterAdsGood = this.adsGood
      ? this.adsGood[0].subTabs.splice(0, 1)
      : [];
    this.getList();
  },
  methods: {
    jumpAdd(item) {
      jumpAdv(item);
    },
    async getList() {
      let ret = await this.$Api(newTagList, undefined);
      console.log(ret);
      this.isLoading = false;
      if (ret.code == 200) {
        let list = ret.data.areaPages ? ret.data.areaPages : [];
        this.dataList = list;
      }
    },
    swipeLeft() {
      // this.$router.push("/home/rank");
    },
    swipeRight() {
      // this.$router.push("/home/introduce");
    },
    // 跳好片大赏
    toGoodVideo(id) {
      this.action(3, undefined);
      this.$router.push({ path: "/goodVideo", query: { id: id } });
    },
    // 跳色区
    toSeArea() {
      this.action(2, undefined);
      this.$router.push({ path: "/seArea" });
    },
    // 跳专区
    toMoreVideo(id, name) {
      this.action(1, id);
      this.$router.push({ path: "/moreVideo", query: { id: id, name: name } });
    },
    // 统计点击量
    async action(location, itemId) {
      let req = {
        location: location,
        childLocation: itemId,
        type: 1,
      };
      await this.$Api(buried, req);
    },
  },
};
</script>
<style lang="scss" scoped>
.loading_box {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 30px;
  }
}
.tag-tag {
  padding: 2px 10px;
}
.lunbo {
  height: 150px;
  position: relative;
}
.swiper-all {
  width: 100%;
  height: 100%;
}
.ad-img {
  width: 100%;
  height: 100%;
}
.tag-img {
  width: 100%;
  height: 89px;
  border-radius: 45px;
  margin-top: 10px;
  overflow: hidden;
}
.swiper-pagination {
  right: 16px;
  bottom: 10px;
}
.swiper-pagination /deep/ .swiper-pagination-bullet {
  margin-left: 6px;
  width: 6px;
  height: 6px;
}
.swiper-pagination /deep/ .swiper-pagination-bullet-active {
  width: 24px;
  background: #ffffff;
  border-radius: 4px;
}
.sequ {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
}
.guochan {
  margin-top: 14px;
  .l_adv {
    height: 74px;
    overflow: hidden;
    padding-top: 10px;
    /deep/ .swiper-container,
    /deep/ .swiperSlide {
      height: 100%;
    }
    /deep/ img {
      object-fit: fill !important;
    }
  }
}
.red-heard {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("../../assets/png/red_heart.png") center center no-repeat;
  background-size: 100%;
  vertical-align: -4px;
  margin-right: 8px;
}
.sequ-img {
  margin-top: 5px;
  width: 100%;
  height: 101px;
  border-radius: 10px;
  overflow: hidden;
}
.more {
  font-size: 13px;
  color: $greyishBrownTwo;
  font-weight: normal;
}
.go-more {
  display: inline-block;
  width: 8.5px;
  height: 10.6px;
  margin-left: 3px;
  background: url("../../assets/png/go_more.png") center center no-repeat;
  background-size: 100%;
}
.video-list {
  flex-wrap: wrap;
}
.tag-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.smallHorizontal {
  display: flex;
  overflow-x: auto;
  .smallHorizontalItem {
    margin-right: 16px;
    width: 220px;
    // height: 120px;
    /deep/ .cover-img {
      width: 220px;
      height: 120px;
    }
  }
  .smallHorizontalItem:last-child {
    margin-right: 0;
  }
}

.bigHorizontal {
  .bigHorizontalItem {
    width: 100%;
    /deep/ .cover-img {
      width: 100%;
      height: 170px;
    }
  }
}

.adv /deep/ .swiperSlide {
  height: 156.25px !important;
}
.advBox {
  padding: 12px 16px 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  // margin-top: -59px;
  grid-gap: 11px;
  .advItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    .advImgBox {
      width: 60px;
      height: 60px;
      border-radius: 12px;
      overflow: hidden;
    }
    .advTitle {
      margin-top: 4px;
      font-size: 12px;
      color: #333;
      width: 60px;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; // 默认不换行；
      text-align: center;
    }
  }
}
</style>
